.sf__form__container__flex {
    display: flex;
    flex-direction: column;
}

.sf__form__name__flex {
    display: flex;
    gap: 30px;
}

.sf__input__form {
    margin-bottom: 15px;
}

.sf__name__input,
.sf__surname__input {
    flex: 45%;
}

.sf__input__label__req::after {
    content: '*';
    color: red;
}

.sf__form__container__email {
    position: relative;
}

.sf__email__suggestion {
    margin-top: -15px;
    width: 100%;
    background: white;
    overflow: hidden;
    max-height: 0;
    margin-bottom: 15px;
    transition: all .2s ease-in-out;
}

.sf__email__suggestion__true {
    max-height: fit-content;
    overflow: auto;
}

.sf__suggestion__button {
    padding: 12px 8px;
    cursor: pointer;
    transition: all .1s ease-in-out;
}

.sf__suggestion__button:hover {
    background: #efefef;
    font-weight: bold;
}

.sf__input__extra__info {
    font-size: 0.75rem;
}

button {
    background: rgb(27, 70, 145);
    padding: 10px 30px;
    border-radius: 5px;
    color: #fff;
}

button:disabled {
    opacity: .7;
}